<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="6">
          <h2 class="text-h5 mb-4">Failed Orders</h2>
        </v-col>
        <!-- minDate="minDate"
            :maxDate="maxDate" -->
        <v-col cols="6">
          <date-range-picker
            style="float: right"
            ref="picker"
            opens="left"
            :max-date="max_date"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
            :showWeekNumbers="true"
            :showDropdowns="true"
            :autoApply="false"
            v-model="dateRange"
            @update="updateValues"
            :linkedCalendars="false"
          >
            <template v-slot:input="picker" style="min-width: 350px">
              {{ picker.startDate | date_format }} - {{ picker.endDate | date_format }}
            </template>
          </date-range-picker>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="4">
          <v-card>
            <v-data-iterator :items="failedShops" item-key="name" :items-per-page="10">
              <template v-slot:default="props">
                <v-list rounded>
                  <v-list-item-group color="primary">
                    <div v-for="item in props.items" :key="item.id" ref="order">
                      <v-list-item @click="renderFailedOrders(item)">
                        <v-list-item-content>
                          <!-- <v-badge avatar bordered overlap>
                          <template v-slot:badge>
                            <v-avatar>
                              <v-img src="https://cdn.vuetifyjs.com/images/logos/v.png"></v-img>
                            </v-avatar>
                          </template>
                        </v-badge> -->

                          <v-list-item-title v-text="item.specifier"></v-list-item-title>
                          <!-- <v-badge bordered color="error" icon="mdi-lock" overlap> </v-badge> -->
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-chip color="red">
                            {{ Object.keys(item.failed_orders).length }}
                          </v-chip>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider class="mb-2"></v-divider>
                    </div>
                  </v-list-item-group>
                </v-list>
              </template>
            </v-data-iterator>
          </v-card>
        </v-col>

        <v-col cols="8">
          <v-card>
            <div v-if="!failedOrders.length" style="padding: 200px 100px" class="text-center">
              <h1>No Failed Orders Found!!</h1>
              <p>Please select shop from the sidebar!</p>
            </div>

            <div v-else style="padding: 20px">
              <h3 class="mb-3">Failed Orders</h3>
              <v-data-table :headers="failed_headers" :items="failedOrders" :items-per-page="15" class="elevation-1">
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.id" :set="(buyer_details = JSON.parse(item.buyer_details))">
                      <td>
                        <i class="primary--text">#{{ item.receipt_id }}</i>
                      </td>
                      <td v-html="renderShopifyOrderResult(item)" @click="handleOrderClick"></td>

                      <td>{{ item.creation_tsz | date_format('MMM Do, Y') }}</td>
                      <td>
                        <v-chip small class="ma-2" color="green" text-color="white" v-if="item.was_shipped == '1'"
                          >Completed</v-chip
                        >
                        <v-chip small class="ma-2" color="red" text-color="white" v-else>Unshipped</v-chip>
                      </td>

                      <td>{{ buyer_details.currency_code }} &nbsp; {{ parseFloat(item.price).toFixed(2) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import moment from 'moment'
import _ from 'lodash'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: { DateRangePicker },

  data() {
    return {
      opens: false,
      dateRange: {
        startDate: '2022-10-10',
        endDate: '2022-11-10',
      },
      max_date: '',

      failedShops: [],

      failedOrders: [],
      failed_headers: [
        {
          text: 'Receipt ID',
        },
        {
          text: 'Remarks',
        },
        {
          text: 'Status',
        },
        {
          text: 'Customer',
        },
        {
          text: 'Total',
        },
      ],
    }
  },
  mounted() {
    this.dateRange.startDate = moment().subtract(30, 'days').format('YYYY-MM-DD')
    this.dateRange.endDate = moment().format('YYYY-MM-DD')
    this.max_date = moment().format('YYYY-MM-DD')
    console.log(moment().toDate() - 30)
    this.fetchFailedOrders()
  },
  methods: {
    async fetchFailedOrders() {
      console.log('fetch faile doerder');
      try {
        // this.is_loading = true
        let response = await axios.get(`admin/get-failed-orders`, {
          params: {
            start_date: this.dateRange.startDate,
            end_date: this.dateRange.endDate,
            // page: this.orders.current_page,
            // keyword: this.search_keyword,
          },
        })

        // this.failedShops = res``
        if (response.data.success) {
          this.failedShops = response.data.data
        }
      } catch (e) {
      } finally {
        // this.is_loading = false
      }
    },

    renderFailedOrders(shop) {
      this.failedOrders = _.toArray(shop.failed_orders)
    },

    updateValues(data) {
      this.dateRange.startDate = moment(data.startDate).format('YYYY-MM-DD'); 
      this.dateRange.endDate = moment(data.endDate).format('YYYY-MM-DD');
      this.fetchFailedOrders();
    },

    async handleOrderClick(e) {
      let elt = e.target.closest(".reprocess_webhook").dataset;
     
      if (elt) {
        let response = await axios.get(`admin/shop/`+elt.shop_id+`/order/`+elt.receipt_id+`/webhook/reprocess`);
      }
    },

    renderShopifyOrderResult(order) {
      let info_text = null
      let order_status_url = false
      let order_id = ''
      let parsed_shopify_order_result = {}
      if (order.shopify_order_result) {
        parsed_shopify_order_result =
          typeof order.shopify_order_result == 'string'
            ? JSON.parse(order.shopify_order_result)
            : order.shopify_order_result

        order_status_url = parsed_shopify_order_result.order_status_url
        info_text = parsed_shopify_order_result.message
        if (!info_text) {
          info_text =
            'Order is fetched from Etsy. Our system is processing this order, and it will be sent over to Shopify in a few minutes.'
        }
        if (order_status_url) {
          order_id =
            '<a class="dpl_link_text_green" target="_blank" href="' + order_status_url + '">View in Shopify</a>'
        }
      }
      if (order.order_sync_status == '0' && parseInt(order.was_paid) == 1) {
        if (info_text == null) {
          info_text =
            'Order is fetched from Etsy. Our system is processing this order, and it will be sent over to Shopify in a few minutes.'
        }
        return '<p>' + info_text + '</p>'
      } else if (order.order_sync_status == '-1') {
        return (
          "<p class='dpl_error_text'>" +
          (parsed_shopify_order_result.message ||
            'Congratulations! This order has been successfully sent to Shopify for fulfillment.') +
          '</p>' +
          order_id
        )
      } else if (order.order_sync_status == '-7') {
        return (
          "<p class='dpl_error_text'>You have not provided tracking number in Shopify. Tracking number is mandatory for Etsy to fulfill the order. <a class='dpl_link_text_green' target='_blank' href='https://www.etsy.com/your/orders/sold/completed?order_id=" +
          order.receipt_id +
          "'>Click here to fulfil this order.</a></p>"
        )
      } else if (order.order_sync_status == '-8') {
        return (
          "<p class='dpl_error_text reprocess_webhook' data-receipt_id='"+order.receipt_id+"' data-shop_id='"+order.shop_id+"'> cannot update tracking/fulfillment on Etsy due to API limit exceed. Click to <button> Reprocess.</button></p>"
        )
      } else if (order.order_sync_status == '-2') {
        return (
          '<p class="dpl_error_text">' +
          (parsed_shopify_order_result.message || 'Order post failed due to inventory issue') +
          '</p>' +
          order_id
        )
      } else if (order.order_sync_status == '2') {
        return (
          '<p>' +
          (parsed_shopify_order_result.message ||
            'Congratulations! This order has been successfully sent to Shopify for fulfillment.') +
          '</p>' +
          order_id
        )
      } else if (order.order_sync_status == '3') {
        return '<p>Order is being processed by the system.</p>'
      } else if (order.order_sync_status == '4' || parseInt(order.was_paid) == 0) {
        return '<p>This order has been placed from Etsy but is awaiting payment. Once the payment has been made, this order will be sent to Shopify for the next step.</p>'
      } else if (order.order_sync_status == '7') {
        info_text = parsed_shopify_order_result.message || 'Inventory Synced'
        return '<p>' + info_text + '</p>'
      } else {
        if (order.order_sync_status == '0' || order.order_sync_status == undefined) {
          return '<p>Processing...</p>'
        } else {
          return '<p>Congratulations! This order has been successfully sent to Shopify and is fulfilled.</p>' + order_id
        }
      }
    },
  },
}
</script>